export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгоритмы"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithms"])}
      },
      "de": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmen"])}
      },
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmos"])}
      },
      "pt": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmos"])}
      },
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmes"])}
      },
      "it": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmi"])}
      },
      "pl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorytmy"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["算法"])}
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルゴリズム"])}
      }
    }
  })
}
