export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монет"])}
      },
      "en": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сoins"])}
      },
      "de": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Münzen"])}
      },
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmos"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monedas"])}
      },
      "pt": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moedas"])}
      },
      "fr": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces de monnaie"])}
      },
      "it": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monete"])}
      },
      "pl": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monety"])}
      },
      "zh": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名稱"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬幣"])}
      },
      "ja": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン"])}
      }
    }
  })
}
