<template lang="pug">
table.data-table.data-table_algorithms(v-if="algorithms && algorithms.length")
  thead.data-table__thead
    tr.data-table__row
      template(v-for="(header, i) in headers", :key="i")
        //th.data-table__head(v-if="header.sort")
        //  button.data-table__head-sort {{header.text}}
        th.data-table__head {{ header.translation ? t(header.translation) : '' }}
  tbody.data-table__body
    tr.data-table__row.data-table__row_hover(v-for="(item, i) in algorithms", :key="i", @click="goToAlgorithmPage(item.slug)")
      td.data-table__cell.data-table__cell_number {{i + 1}}.
      td.data-table__cell.data-table__cell_algorithm-name {{item.name}}
      td.data-table__cell.data-table__cell_coins {{ item.count_coins }}
</template>

<script>
import '@/assets/styles/components/data-table.sass'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AlgorithmsTable',
  props: {
    algorithms: Array
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  data () {
    return {
      headers: [
        { translation: '', sort: false },
        { translation: 'name', sort: true },
        { translation: 'coins', sort: true }
      ],
      isPoolsPage: false
    }
  },
  methods: {
    goToAlgorithmPage (slug) {
      if (this.$i18n.locale === 'en') {
        this.$router.push({ name: 'Algorithm', params: { slug } })
      } else {
        this.$router.push({ name: 'Algorithm', params: { slug, locale: this.$i18n.locale } })
      }
    }
  }
}
</script>
<i18n>
{
  "ru": {
    "name": "Название",
    "coins": "Монет"
  },
  "en": {
    "name": "Name",
    "coins": "Сoins"
  },
  "de": {
    "name": "Name",
    "coins": "Münzen"
  },
  "es": {
    "title": "Algoritmos",
    "name": "Nombre",
    "coins": "Monedas"
  },
  "pt": {
    "name": "Nome",
    "coins": "Moedas"
  },
  "fr": {
    "name": "Nom",
    "coins": "Pièces de monnaie"
  },
  "it": {
    "name": "Nome",
    "coins": "Monete"
  },
  "pl": {
    "name": "Nazwa",
    "coins": "Monety"
  },
  "zh": {
    "name": "名稱",
    "coins": "硬幣"
  },
  "ja": {
    "name": "名前",
    "coins": "コイン"
  }
}
</i18n>
