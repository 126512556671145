<template lang='pug'>
algorithms-info(v-if="algorithmsInfo", :info="algorithmsInfo")
pagination(v-if="pages > 1", :current-page="page", :pages="pages")
</template>

<script>
import { getAlgorithms } from '../assets/scripts/API'
import AlgorithmsInfo from '../components/tables/AlgorithmsInfo'
import Pagination from '../components/Pagination'
import { addMeta } from '../assets/scripts/add-meta'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AlgorithmsPage',
  components: { Pagination, AlgorithmsInfo },
  data () {
    return {
      page: 1,
      pages: 1,
      algorithmsInfo: null
    }
  },
  methods: {
    async getDataPage (page) {
      const data = await getAlgorithms(page)
      this.algorithmsInfo = data
      this.pages = data.pages
      this.page = data.page
    }
  },
  mounted () {
    this.getDataPage(this.page)
    addMeta(this.t('title'), this.t('description'))
  },
  beforeRouteUpdate (to, from, next) {
    this.page = +to.query.page
    this.getDataPage(this.page)
    next()
  },
  watch: {
    '$i18n.locale' () {
      addMeta(this.t('title'), this.t('description'))
    }
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
      messages: {
        ru: {
          title: 'Алгоритмы майнинга и шифрования криптовалют',
          description: 'В каждой криптовалюте используется алгоритм шифрования, именно его обрабатывает майнинговое оборудование и майнинг пулы'
        },
        en: {
          title: 'Cryptocurrency mining and encryption algorithms',
          description: 'Each cryptocurrency uses an encryption algorithm, which is processed by mining equipment and mining pools'
        },
        es: {
          title: 'Algoritmos de encriptación y minería de criptomonedas',
          description: 'Cada criptomoneda utiliza un algoritmo de cifrado, que es procesado por equipos de minería y grupos de minería.'
        },
        pt: {
          title: 'Algoritmos de mineração e criptografia de criptomoedas',
          description: 'Cada criptomoeda usa um algoritmo de criptografia, que é processado por equipamentos de mineração e pools de minera'
        },
        de: {
          title: 'Kryptowährungs-Mining und Verschlüsselungsalgorithmen',
          description: 'Jede Kryptowährung verwendet einen Verschlüsselungsalgorithmus, der von Mining-Geräten und Mining-Pools verarbeitet wird'
        },
        fr: {
          title: 'Algorithmes de minage et de chiffrement de crypto-monnaie',
          description: 'Chaque crypto-monnaie utilise un algorithme de cryptage, qui est traité par l\'équipement de minage et les pools de minage'
        },
        it: {
          title: 'Algoritmi di crittografia e mining di criptovaluta',
          description: 'Algoritmi di crittografia e mining di criptovaluta'
        },
        pl: {
          title: 'Algorytmy kopania i szyfrowania kryptowalut',
          description: 'Każda kryptowaluta wykorzystuje algorytm szyfrowania, który jest przetwarzany przez sprzęt wydobywczy i pule wydobywcze'
        },
        zh: {
          title: '加密貨幣挖掘和加密算法',
          description: '每種加密貨幣都使用一種加密算法，由挖礦設備和礦池處理'
        },
        ja: {
          title: '暗号通貨マイニングと暗号化アルゴリズム',
          description: '各暗号通貨は、マイニング機器とマイニングプールによって処理される暗号化アルゴリズムを使用します'
        }
      }
    })
    return { t }
  }
}
</script>
