<template lang='pug'>
.info(v-if="info")
  .info__cont.cont
    h2.info__title.title {{t('title')}}
    .info__table-box
      algorithms-table(v-if="info.algorithms", :algorithms="info.algorithms")
</template>

<script>
import '@/assets/styles/components/info.sass'
import AlgorithmsTable from './AlgorithmsTable'
import { useI18n } from 'vue-i18n'
export default {
  name: 'AlgorithmsInfo',
  components: { AlgorithmsTable },
  props: {
    info: Object
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  },
  mounted () {
    this.isPoolsPage = this.$route.name === 'Pools'
  }
}
</script>

<i18n>
{
  "ru": {
    "title": "Алгоритмы"
  },
  "en": {
    "title": "Algorithms"
  },
  "de": {
    "title": "Algorithmen"
  },
  "es": {
    "title": "Algoritmos"
  },
  "pt": {
    "title": "Algoritmos"
  },
  "fr": {
    "title": "Algorithmes"
  },
  "it": {
    "title": "Algoritmi"
  },
  "pl": {
    "title": "Algorytmy"
  },
  "zh": {
    "title": "算法"
  },
  "ja": {
    "title": "アルゴリズム"
  }
}
</i18n>
